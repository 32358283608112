import React from 'react';

const EducationCard = ({ education }) => {
  return (
    <div className="w-full max-w-5xl bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:shadow-xl hover:-translate-y-1 hover:border-purple-600">
      <div className="p-6">
        <div className="flex flex-col md:flex-row items-center mb-6">
          <img 
            src={education.img} 
            className="h-16 md:h-12 rounded-lg mb-4 md:mb-0" 
            alt="School Logo" 
          />
          <div className="ml-0 md:ml-4 text-center md:text-left">
            <h2 className="text-xl font-bold text-gray-800">{education.school}</h2>
            <p className="text-sm font-medium text-gray-600">{education.degree}</p>
            <p className="text-xs font-normal text-gray-500">{education.date}</p>
          </div>
        </div>
        {education.grade && (
          <div className="text-lg font-medium text-gray-600 mb-4">
            <b>Grade:</b> {education.grade}
          </div>
        )}
        <div className="text-base font-normal text-gray-700">
          <p className="text-justify">{education.desc}</p>
        </div>
      </div>
    </div>
  );
};

export default EducationCard;
