import React from 'react';
import { useState } from 'react';
import ProjectCard from '../component/ProjectCard';
import { projects } from '.././data/constants';

const Projects = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState('all');
  const filteredProjects = toggle === 'all'
  ? projects
  : projects.filter((item) => item.category === toggle);
  return (
    <div id="projects" className=" pt-20  ">
      <div className="max-w-6xl mx-auto px-8">
        <h2 className="text-4xl font-bold text-center text-white mb-4">Projects</h2>
        <p className="text-lg text-center text-white  mb-8">
          I have worked on a wide range of projects. From web apps to android apps. Here are some of my projects.
        </p>
        <div className="flex justify-center mb-8 ">
          <button
            className={`border border-purple-500 rounded-l-lg px-4 py-2 focus:outline-none ${
              toggle === 'all' ? 'bg-purple-500 text-white' : 'text-purple-500'
            }`}
            onClick={() => setToggle('all')}
          >
            All
          </button>
          <button
            className={`border border-purple-500 px-4 py-2 focus:outline-none ${
              toggle === 'fullStack app' ? 'bg-purple-500 text-white' : 'text-purple-500'
            }`}
            onClick={() => setToggle('fullStack app')}
          >
            FULLSTACK
          </button>
          <button
            className={`border border-purple-500  px-4 py-2 focus:outline-none ${
              toggle === 'frontend App' ? 'bg-purple-500 text-white' : 'text-purple-500'
            }`}
            onClick={() => setToggle('frontend App')}
          >
            FRONTEND 
          </button>
          <button
            className={`border border-purple-500 rounded-r-lg px-4 py-2 focus:outline-none ${
              toggle === 'backend app' ? 'bg-purple-500 text-white' : 'text-purple-500'
            }`}
            onClick={() => setToggle('backend app')}
          >
            BACKEND
          </button>
        </div>
        <div className="flex flex-wrap justify-center gap-16 cursor-pointer">
        {filteredProjects.length > 0 ? (
            filteredProjects.map((project) => (
              <ProjectCard key={project.id} project={project} openModal={openModal} setOpenModal={setOpenModal} />
            ))
          ) : (
            <span className="text-white font-bold text-2xl">No Projects available</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
