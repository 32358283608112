import React, { useState } from 'react';
import { DiCssdeck } from 'react-icons/di';
import { FaBars } from 'react-icons/fa';
import { Bio } from './../data/constants';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <nav className="bg-custom-bg sticky top-0 z-50">
      <div className=" container mx-auto flex justify-between items-center h-20 max-w-5xl   ">
        <Link to="/" className="flex items-center text-white px-4" onClick={scrollToTop}>
          <DiCssdeck size="3rem" /> <span className="ml-2 font-bold text-xl">Himansu</span>
        </Link>
        <div className="md:hidden px-4">
          <FaBars className="text-white text-2xl cursor-pointer" onClick={() => setIsOpen(!isOpen)} />
        </div>
        <ul className="hidden md:flex space-x-8 text-white">
          <li><a href="#about" className="hover:text-blue-400">About</a></li>
          <li><a href="#skills" className="hover:text-blue-400">Skills</a></li>
          <li><a href="#projects" className="hover:text-blue-400">Projects</a></li>
          <li><a href="#experience" className="hover:text-blue-400">Experience</a></li>
          <li><a href="#education" className="hover:text-blue-400">Education</a></li>
          <li><a href="#contact" className="hover:text-blue-400">Contact</a></li>

        </ul>
        <div className="hidden md:flex">
          <a
            href={Bio.github}
            target="_blank"
            rel="noopener noreferrer"
            className="border border-blue-500 text-blue-500 mr-4 px-4 py-2 rounded hover:bg-blue-500 hover:text-white transition duration-300"
          >
            Github Profile
          </a>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden bg-gray-800 text-white flex flex-col items-center space-y-4 py-4 ">
          <a href="#about" className="hover:text-blue-400" onClick={() => setIsOpen(false)}>About</a>
          <a href="#skills" className="hover:text-blue-400" onClick={() => setIsOpen(false)}>Skills</a>
          <a href="#projects" className="hover:text-blue-400" onClick={() => setIsOpen(false)}>Projects</a>
          <a href="#experience" className="hover:text-blue-400" onClick={() => setIsOpen(false)}>Experience</a>
          <a href="#education" className="hover:text-blue-400" onClick={() => setIsOpen(false)}>Education</a>
          <a href="#contact" className="hover:text-blue-400" onClick={() => setIsOpen(false)}>Contact</a>

          <a
            href={Bio.github}
            target="_blank"
            rel="noopener noreferrer"
            className="border border-blue-500 text-blue-500 px-4 py-2 rounded hover:bg-blue-500 hover:text-white transition duration-300"
          >
            Github Profile
          </a>
        </div>
      )}
    </nav>
  )
}

export default Navbar