import React from 'react';
import { motion } from 'framer-motion';

const ProjectCard = ({ project, setOpenModal }) => {
  return (
    <motion.div
      className="w-full md:w-1/2 lg:w-1/4 bg-gray-800 border border-blue-600 rounded-lg p-6 shadow-lg transition-transform transform hover:scale-105"
      whileHover={{ rotate: 2, scale: 1.03 }}
      transition={{ duration: 0.3 }}
      onClick={() => setOpenModal({ state: true, project: project })}
    >
      <img
        src={project.image}
        alt={project.title}
        className="w-full h-40 object-cover rounded-lg transition duration-300 transform hover:scale-110"
      />
      <div className="p-4">
        <h3 className="text-lg font-semibold text-white truncate">{project.title}</h3>
        <p className="text-xs mb-1 text-gray-400">{project.date}</p>
        <p className="text-base text-gray-300 mb-3 line-clamp-2">
          {project.description}
        </p>
        
        <div className="flex flex-wrap gap-2">
          {project.tags?.map((tag, index) => (
            <span
              key={index}
              className="bg-blue-600 bg-opacity-20 text-blue-500 text-xs font-medium px-3 py-1 rounded-full border border-blue-500 transition duration-200 hover:bg-blue-500 hover:bg-opacity-40"
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectCard;
