import { BrowserRouter } from "react-router-dom";
import Navbar from "./component/Navbar";
import HeroSection from "./component/HeroSection";
import Skills from "./component/Skills";
import Project from "./component/Project";
import Education from "./component/Education";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import { useState } from "react";
import ProjectDetails from './component/ProjectDetails'
import Experences from "./component/Experences";
function App() {
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  // console.log(openModal)
  return (
    <BrowserRouter> 
      <div className="bg-custom-bg1">
        <Navbar />
        <HeroSection />
        <div className="bg-custom-gradient w-full  ">
          <Skills />
        </div>
        <div className="bg-custom-gradient w-full  md:clip-path-custom-shape">
          <Project openModal={openModal} setOpenModal={setOpenModal} />
          <Experences/>
          <Education />
          <Contact />
        </div>
        <Footer />
        {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          }
              </div>
    </BrowserRouter>
  );
}

export default App;
