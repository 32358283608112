import React from 'react';
import HeroImg from '../../src/images/himansu-portfolio.jpg';
//import HeroBgAnimation from '../component/HeroBgAnimation.jsx';
import Typewriter from 'typewriter-effect';
import { Bio } from './../data/constants.js';
import { Tilt } from "react-tilt";
import './styles.css'
function HeroSection() {
  return (
    <div id="about" className="relative bg-custom-bg flex justify-center items-center min-h-screen  pt-14 pb-20 px-8 z-10 clip-path-polygon">
      {/* <div className="hero-bg">
        <HeroBgAnimation />
      </div> */}
      <div className="relative flex justify-between items-center w-full max-w-5xl px-8 flex-col md:flex-row md:mx-auto z-10">
        <div className=" order-2 md:order-1 w-full md:w-3/4 flex flex-col items-center md:items-start  first-letter:mb-8 md:mb-0 ">
          <h1 className="font-bold text-5xl text-white leading-tight text-center md:text-left">
            Hi, I am <br /> {Bio.name}
          </h1>
          <div className="font-semibold text-2xl flex gap-3 text-white leading-tight mt-4 text-center md:text-left">
            I am a
            <span className="text-blue-500 cursor-pointer ">
              <Typewriter
                options={{
                  strings: Bio.roles,
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </div>
          <p style={{ textAlign: 'justify' }} className="text-lg leading-relaxed text-gray-400 mt-4 text-center md:text-left">
            {Bio.description}
          </p>
          {/* <a
            href={Bio.resume}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-6 px-6 py-3 bg-gradient-to-br from-purple-600 to-pink-500 text-white rounded-lg text-lg font-semibold shadow-lg transform transition duration-300 hover:scale-105"
          >
            Check Resume
          </a> */}
        </div>
        <Tilt className=" order-1 md:order-2 w-full md:w-1/2 flex mx-auto justify-center md:justify-end mb-6 md:mb-0 md:ml-12">
        <div className=' mx-auto' >
          <img
            src={HeroImg}
            alt="selfimage"
            className="relative w-full h-full max-w-full rounded-full border-4 border-blue-500 z-10"
          />
        </div>
        </Tilt>
      </div>
    </div>
  )
}

export default HeroSection