import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { education } from '../data/constants'; // Adjusted path for clarity
import EducationCard from '../component/EducationCard';

const Education = () => {
  return (
    <div id="education" className="flex flex-col justify-center items-center pt-20">
      <div className="relative flex flex-col items-center justify-between w-full max-w-[1350px] pt-10 gap-3 md:flex-col">
        <div className="text-4xl font-bold text-center text-white mb-4">
          Education
        </div>
        <div className="text-lg text-center text-white mb-8">
          My education has been a journey of self-discovery and growth. My educational details are as follows.
        </div>

        {/* Conditional rendering for small screens */}
        <div className="w-full max-w-5xl mt-2 flex flex-col items-center justify-center gap-3 px-4 md:hidden"> {/* Added padding here */}
          {education.map((item, index) => (
            <div key={index} className="w-full">
              <EducationCard education={item} />
            </div>
          ))}
        </div>

        {/* Timeline for medium and larger screens */}
        <div className="hidden md:flex w-full max-w-5xl mt-2 flex-col items-center justify-center gap-3 md:items-end">
          <Timeline position="alternate-reverse">
            {education.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <EducationCard education={item} />
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </div>
      </div>
    </div>
  );
};

export default Education;
