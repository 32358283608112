import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Snackbar,Alert  } from '@mui/material';

const Contact = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const form = useRef();
  const service_id = process.env.REACT_APP_SERVICE_KEY;
  const template_id = process.env.REACT_APP_TEMPLATE_KEY;
  const public_id = process.env.REACT_APP_PUBLIC_KEY;
// console.log(service_id)
const validateForm = () => {
  const formData = new FormData(form.current);
  const email = formData.get('from_email');
  const name = formData.get('from_name');
  const subject = formData.get('form_subject');
  const message = formData.get('message');

  if (!email || !name || !subject || !message) {
    return 'All fields are required.';
  }
  return '';
};


  const handleSubmit = (e) => {
    e.preventDefault();
    const errorMessage = validateForm();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    emailjs.sendForm(service_id, template_id, form.current, public_id)
      .then(() => {
        setOpen(true);
        form.current.reset();
        setError('');
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <div id='contact' className="min-h-screen flex flex-col justify-center items-center relative py-0 pb-16 md:pb-0">
    <div className="relative flex flex-col items-center justify-between w-full max-w-[1350px] px-4 pt-10 gap-3 md:flex-col">
      <div className="text-4xl font-bold text-center text-white mb-4">Contact</div>
      <div className="text-lg text-center text-white mb-8">
        Feel free to reach out to me for any questions or opportunities!
      </div>
      <form ref={form} onSubmit={handleSubmit} className="w-full max-w-md mt-2 flex flex-col items-center justify-center gap-3 md:items-end">
        <div className="text-xl mx-auto font-semibold text-white">Email Me 🚀</div>
        {error && <Alert severity="error" className="w-1/2 mx-auto">{error}</Alert>}
        <input type="email" placeholder="Your Email" name="from_email" className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary md:px-2" />
        <input type="text" placeholder="Your Name" name="from_name" className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary" />
        <input type="text" placeholder="Subject" name="form_subject" className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary" />
        <textarea placeholder="Message" rows="4" name="message" className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary" />
        <button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Send</button>
      </form>
      <Snackbar className='relative '
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message="Email sent successfully!"
        severity="success"
      />
    </div>
  </div>
  );
};

export default Contact;
