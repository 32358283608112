import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import CallIcon from '@mui/icons-material/Call';
import { Bio } from '../data/constants';

function Footer() {
  return (
    <div className="w-full py-8  from-blue-100 to-purple-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center">
          <h1 className="font-semibold text-2xl text-white mb-1">Himansu Sekhar Sahu</h1>
          <h3 className="font-semibold text-sm text-gray-500 mb-1 ">Bhubaneswar, Odisha</h3>
          <h3 className="font-semibold text-sm text-gray-500 mb-4 ">Contact no: +91-7438023456</h3>
          <nav className="mb-4">
            <a href="#about" className="text-sm text-white mr-4 hover:text-blue-800 transition duration-300">About</a>
            <a href="#skills" className="text-sm text-white mr-4 hover:text-blue-800 transition duration-300">Skills</a>
            <a href="#projects" className="text-sm text-white mr-4 hover:text-blue-800 transition duration-300">Projects</a>
            <a href="#education" className="text-sm text-white mr-4 hover:text-blue-800 transition duration-300">Education</a>
            <a href="#contact" className="text-sm text-white mr-4 hover:text-blue-800 transition duration-300">Contact</a>
          </nav>
          <div className="flex mb-4">
            <a href={Bio.facebook} target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-800 mr-4 transition duration-300"><FacebookIcon /></a>
            <a href={Bio.twitter} target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-800 mr-4 transition duration-300"><TwitterIcon /></a>
            <a href={Bio.linkedin} target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-800 mr-4 transition duration-300"><LinkedInIcon /></a>
            <a href={Bio.insta} target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-800 mr-4 transition duration-300"><InstagramIcon /></a>
            <a href="tel:7438023456" className="text-white hover:text-blue-800 transition duration-300"><CallIcon /></a>
          </div>
          <p className="text-sm text-gray-500">&copy; {new Date().getFullYear()} Himansu Sekhar Sahu. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
