import { CloseRounded } from '@mui/icons-material';
import { Modal } from '@mui/material';
import React from 'react';

const index = ({ openModal, setOpenModal }) => {
    const project = openModal?.project;
    return (
        <Modal open={true} onClose={() => setOpenModal({ state: false, project: null })}>
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-start justify-center overflow-y-scroll transition-all ease duration-500">
                <div className="max-w-3xl w-full mx-4 my-16 rounded-lg bg-gray-800 text-white p-8 flex flex-col relative">
                    <CloseRounded
                        className="absolute top-2 right-4 cursor-pointer"
                        onClick={() => setOpenModal({ state: false, project: null })}
                    />
                    <img className="w-full object-cover rounded-lg mt-8" src={project?.image} alt="Project" />
                    <h1 className="text-2xl font-bold text-white mt-8">{project?.title}</h1>
                    <div className="text-sm text-gray-500 mt-2">{project.date}</div>
                    <div className="flex flex-wrap mt-2 gap-2">
                        {project?.tags.map((tag, index) => (
                            <div key={index} className="text-xs font-medium bg-primary  bg-opacity-15 px-2 py-1 border border-purple-500 rounded-full text-white">{tag}</div>
                        ))}
                    </div>
                    <p style={{textAlign:"justify"}} className="text-base font-normal text-white mt-4">{project?.description}</p>
                    <div className="flex justify-end mt-4">
                      {project?.github && <a href={project?.github} target="_blank" rel="noopener noreferrer" className="text-base font-semibold text-white border border-green-500 px-4 py-2 rounded-md hover:bg-black  mr-2">View Code</a>}  
                       
                       { project?.webapp &&  <a href={project?.webapp} target="_blank" rel="noopener noreferrer" className="text-base font-semibold text-white border border-green-500 px-4 py-2 rounded-md hover:bg-black  mr-2">View Live App</a>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default index;
