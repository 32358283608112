import React from 'react';
import { experiences } from '.././data/constants';
import ExperienceCard from '../component/ExperienceCard';

const Experience = () => {
  return (
    <div id="experience" className="flex flex-col items-center justify-center pt-20">
      <div className="w-full max-w-7xl flex flex-col justify-between items-center gap-4">
        {/* Heading */}
        <div className="text-4xl font-bold text-center text-white mb-4">
          Experience
        </div>
        <div className="text-lg text-center text-white mb-8 max-w-2xl">
          My work experience has been a journey of growth and learning. Here are my professional experiences:
        </div>

        {/* Experience Cards in Column with Responsive Design */}
        <div className="w-full flex flex-col items-center gap-6 md:gap-8">
          {experiences.map((item, index) => (
            <div key={index} className="w-full max-w-xs md:max-w-2xl">
              <ExperienceCard experience={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
