import React from 'react';

const ExperienceCard = ({ experience }) => {
    return (
        <div className="w-full max-w-5xl bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:shadow-xl hover:-translate-y-1 hover:border-purple-600">
            <div className="p-6  ">
                <div className="flex items-center mb-6">
                    <img src={experience.img} alt="Company Logo" className="h-12 md:h-10 rounded-lg" />
                    <div className="ml-4">
                        <h2 className="text-lg font-semibold text-blue-900">{experience.role}</h2>
                        <p className="text-sm font-medium text-gray-600">{experience.company}</p>
                        <p className="text-xs font-normal text-gray-500">{experience.date}</p>
                    </div>
                </div>
                <div className="text-base font-normal text-gray-700 line-clamp-8 text-justify ">
                    {experience.desc}
                </div>
                {experience.skills && (
                    <div className="mt-4">
                        <div className="font-semibold text-blue-900">Skills:</div>
                        <div className="flex flex-wrap gap-2 mt-1">
                            {experience.skills.map((skill, index) => (
                                <div key={index} className="text-sm font-normal text-gray-700 flex items-center">
                                    <span className="mr-1">•</span> {skill}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExperienceCard;
