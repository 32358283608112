import React from 'react';
import { skills } from '../data/constants.js';
//import { motion } from 'framer-motion';
import { Tilt } from "react-tilt";
import './styles.css';

function Skills() {
  return (
    <div id="skills" className="flex flex-col justify-center items-center min-h-screen bg-custom-gradient pt-20 py-16 px-8">
    <div className="flex flex-col items-center max-w-5xl w-full">
      <h2 className="text-4xl lg:text-4xl font-bold text-center text-white mb-4">Skills</h2>
      <p className="text-lg lg:text-xl text-center text-gray-300 max-w-2xl mb-8">
        Here are some of my skills on which I have been working for the past 2 years.
      </p>
      <div className="flex flex-wrap justify-center gap-8">
        {skills.map((skill, index) => (
          <Tilt key={index}>
           
              <div className="max-w-md w-full skill-card bg-gray-800 border border-blue-500 rounded-lg p-6 h-full shadow-lg">
                <h3 className="text-2xl lg:text-3xl font-semibold text-center text-white mb-4">{skill.title}</h3>
                <div className="flex flex-wrap justify-center gap-4">
                  {skill.skills.map((item, idx) => (
                    <div key={idx} className="skill-item flex items-center gap-2 border border-blue-400 text-blue-400 px-4 py-2 rounded">
                      <img src={item.image} alt={item.name} className="w-6 h-6" />
                      <span className="text-white">{item.name}</span>
                    </div>
                  ))}
                </div>
              </div>
           
          </Tilt>
        ))}
      </div>
    </div>
  </div>
  );
}

export default Skills;
